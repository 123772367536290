import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import Loading from './Loading'
import decryptedKey from '../Hostlink/decryptedKey';
import CryptoJS from 'crypto-js';


const TokenView = () => {
    const [userData, setUserdata] = useState(null);
    useEffect(() => {
        const encryptedData = localStorage.getItem('USERLOG');
        if (encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, decryptedKey); const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setUserdata(decryptedData);
        }
    }, []);
    const [isLoading, setIsLoading] = useState(false);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [refreshData, setRefreshData] = useState(false)
    const api = "/unit";
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const Closeform = () => {
        window.location.assign("/TokenView")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "Token",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: 0,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
        console.log(`${hostlink}/logbook`)
        console.log(ld)
        console.log("Log Saved...")
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("search").value.trim();
        if (searchValue === "") {
            axios.get(`${hostlink}/${userData[0].id}/tokenmas/${fromdate}/${todate}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/${userData[0].id}/tokenmas/search/${document.getElementById("search").value}`)
                .then((Response) => { setData(Response.data) })
                .catch(() => { })
        }
    }

    const today = new Date();
    const [savedata, setSavedata] = useState({
        id: "",
        unitname: "",
        shopid: usershopid, fromdate: today.toISOString().split('T')[0], todate: today.toISOString().split('T')[0],
        narration: ""
    })
    const { shopid, unitname, todate, fromdate } = savedata;

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }


    const deleteData = async (e, flname) => {
        try {
            await axios.delete(`${hostlink}/tokenmas/${deleteid}`)
            var ld = { ...logdetail, newdesc: "Name : " + deletemsg, operation: "Delete" }
            setlogdetail(ld);
            saveLog(ld)
            toast.success("Delete Sucessfull")

            setInterval(() => {
                handleCloseModal();
                window.location.assign("/TokenView")
            }, 1000);
        } catch (error) { }
    }


    useEffect(() => {
        setRefreshData(false)
        if (userData && userData[0].id) {
            axios.get(`${hostlink}/${userData[0].id}/tokenmas/${fromdate}/${todate}`)
                .then((Response) => {
                    console.log("Data Fetched");
                    setData(Response.data);
                })
                .catch((Response) => {
                    console.log("Data Not Fetched");
                });
        } else {
            console.log("userData is null or undefined");
        }
    }, [refreshData, userData,fromdate,todate])
    return (
        <div className='p-1'>

            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-3 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-ticket" style={{ width: "20px" }}></i > Token View</h5>
                    </div>
                    <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)" }}>
                        &nbsp; <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>

                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div style={{ maxHeight: "580px", overflow: "auto" }} className='col-12 '>
                        <table className="table table-hover table-striped" style={{}}>
                            <thead>
                                <tr>
                                    <th scope="col" className="hide-on-mobile">Token No</th>
                                    <th scope="col" className="f-size-on-mobile"><span className="hide-on-mobile" >Token Date</span> <span className="detail-on-mobile">Details</span></th>
                                    <th scope="col" className="hide-on-mobile">Type</th>
                                    <th scope="col" className="hide-on-mobile">Token Time</th>
                                    <th scope="col" className="hide-on-mobile">Vehicle No</th>
                                    <th scope="col" className="hide-on-mobile">Item Name</th>
                                    <th scope="col" className="hide-on-mobile">Weight</th>
                                    <th scope="col" className="hide-on-mobile">Party Name </th>
                                    <th scope="col" className="f-size-on-mobile">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((res, x) => (
                                    <tr><th scope="row" className="hide-on-mobile">{res.shopvno}</th>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <span className='show-on-mobile'>Date : </span>  {res.entrydate ? new Date(res.entrydate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"} <br />
                                            <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start" }}>
                                                {res.shopvno !== 0 && (<>Type : {res.tokentype === 0 ? "SALE" : "PURC"}<br /></>)}
                                                {res.shopvno !== 0 && (<>Time : {res.entrytime}<br /></>)}
                                                {res.shopvno !== 0 && (<>Item Name : {res.rawname}<br /></>)}
                                                {res.shopvno !== 0 && (<>Item Name : {res.weight}<br /></>)}
                                                {res.shopvno !== 0 && (<>Party Name : {res.accname}<br /></>)}
                                                <span style={{ color: "red" }}> <b>{res.shopvno !== 0 && (<>Vehicle No : {res.vehicleno}<br /></>)}</b> </span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.tokentype === 0 ? "SALE" : "PURC"}</td>

                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.entrytime}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.vehicleno}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.rawname}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.weight}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.accname}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            {/* <Link to={"/TokenView"} onClick={(e) => { cmdEdit(res.shopvno) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span> */}
                                            <Link onClick={(e) => { handleOpenModal(res.id, res.shopvno) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile">Delete</span></Link>&nbsp;
                                            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                                                <div><p>Are you sure you want to delete this <br /> Token : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                                            </Modal>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}
export default TokenView
