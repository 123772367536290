import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Component/TokenView.css'; // Assuming custom styles if needed
import Loading from "../Component/Loading";
import decryptedKey from '../Hostlink/decryptedKey';
import CryptoJS from 'crypto-js';
import axios from "axios";
import hostlink from "../Hostlink/hostlink";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TokenReport = () => {
    const [userData, setUserData] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        // Load user data from local storage
        const encryptedData = localStorage.getItem('USERLOG');
        if (encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, decryptedKey);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setUserData(decryptedData);
        }

        // Set default dates (today's date)
        const today = new Date().toISOString().split("T")[0];
        setFromDate(today);
        setToDate(today);
    }, []);

    const fetchReport = async () => {
        if (!fromDate || !toDate) {
            toast.error("Please select both From and To dates.");
            return;
        }

        try {
            const response = await axios.get(`${hostlink}/${userData[0].id}/tokenmas/${fromDate}/${toDate}`);
            setReportData(response.data);
            toast.success("Report loaded successfully.");
        } catch (error) {
            console.error("Error fetching report:", error);
            toast.error("Failed to fetch report.");
        }
    };

    const exportToExcel = () => {
        // Placeholder for Excel export logic
        toast.info("Excel export feature coming soon!");
    };

    const exportToPDF = () => {
        // Placeholder for PDF export logic
        toast.info("PDF export feature coming soon!");
    };

    return (
        <div className="container mt-4">
            <h1 className="text-center mb-4">Token Report</h1>
            <div className="card p-3 mb-4">
                <div className="row g-3">
                    <div className="col-md-3">
                        <label htmlFor="fromDate" className="form-label">From Date</label>
                        <input
                            type="date"
                            id="fromDate"
                            className="form-control"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="toDate" className="form-label">To Date</label>
                        <input
                            type="date"
                            id="toDate"
                            className="form-control"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                        />
                    </div>
                    <div className="col-md-2 d-flex align-items-end">
                        <button className="btn btn-primary w-100" onClick={fetchReport}>
                            Show Report
                        </button>
                    </div>
                    <div className="col-md-4 d-flex align-items-end">
                        <button className="btn btn-success me-2" onClick={exportToExcel}>
                            Export to Excel
                        </button>
                        <button className="btn btn-danger" onClick={exportToPDF}>
                            Export to PDF
                        </button>
                    </div>

                </div>
            </div>

            
            <div className="table-responsive">
                <table className="table table-bordered table-striped">
                    <thead className="table-dark">
                        <tr>
                            <th>Token No</th>
                            <th>Token Date</th>
                            <th>Time</th>
                            <th>Vehicle No</th>
                            <th>Item</th>
                            <th>Party</th>
                            <th>Type</th>
                            <th>Bill No</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.length > 0 ? (
                            reportData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.shopvno}</td>
                                    <td>{item.entrydate ? new Date(item.entrydate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                    <td>{item.entrytime}</td>
                                    <td>{item.vehicleno}</td>
                                    <td>{item.rawname}</td>
                                    <td>{item.accname}</td>
                                    <td>{item.tokentype === 0 ? "SALE" : "PURC"}</td>
                                    <td>{item.billno}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </div>
    );
};

export default TokenReport;
