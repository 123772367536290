import React, { useEffect, useRef, useState } from "react";
import './TokenView.css';
import Loading from './Loading'
import decryptedKey from '../Hostlink/decryptedKey';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import axios from "axios";
import hostlink from "../Hostlink/hostlink";
const TokenSale = () => {
    const [query, setQuery] = useState('');
    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end

            // Ensure the event target is an input element and supports selection range
            if (event.target instanceof HTMLInputElement && ['text', 'password', 'search', 'tel', 'url'].includes(event.target.type)) {
                event.target.setSelectionRange(0, event.target.value.length); // Select the input value
            }
        }

        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus(); // Cycle back to the first element if Tab is pressed on the last element
            }
        }
    };
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };
    const inputRef = useRef(null);
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].vehicleno);
            setFormData({
                ...formData,
                vehicleno: filteredData[selectedIndex].vehicleno
            });
        }
    };
    const handleItemClick = (index) => {
        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].vehicleno);
        setFormData({
            ...formData,
            vehicleno: filteredData[index].vehicleno,
        });
        document.getElementById("pcs").focus();
    };
    const [selectedItem, setSelectedItem] = useState(null);
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.billno);
        setListVisible(false);
        setSelectedIndex(index);
    };
    let totalAmount = 0;
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const [vedhicledata, setVedhicledata] = useState([]);
    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.vehicleno.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const [userData, setUserdata] = useState(null);
    useEffect(() => {
        const encryptedData = localStorage.getItem('USERLOG');
        if (encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, decryptedKey); const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setUserdata(decryptedData);
        }
    }, []);


    useEffect(() => {
        if (userData && userData[0].id) {
            axios.get(`${hostlink}/${userData[0].id}/item`).then((Response) => {
                setDataItem(Response.data)
            }).catch((Response) => { "data Not Found" })
            axios.get(`${hostlink}/${userData[0].id}/accmas`)
                .then((Response) => {
                    setDataParty(Response.data);
                })
            axios.get(`${hostlink}/${userData[0].id}/tokenmasPending/0`)
                .then((Response) => {
                    setData(Response.data);
                })


        } else {
            console.log("userData is null or undefined");
        }
    }, [userData])
    const [formData, setFormData] = useState({
        weight: "1000.00",
        vehicleno: "",
        rawcode: 0,
        acccode: 0,
        tokenno:"",
        tokentype: 0,
        loading:0,
        freight:0,
        acccodeto:0,
        entrytime: "",
        gateno: "",
        rawname: "",
        accname: ""

    });
    const { weight, vehicleno, rawcode, acccode,acccodeto, gateno, tokentype, entrytime, accname, rawname,freight,loading,tokenno } = formData;
    useEffect(() => {
        totalAmount = 0; // Reset totalAmount
        if (userData && userData[0].id) {
            axios
                .get(`${hostlink}/${userData[0].id}/LoadTokenData/0/${vehicleno}`)
                .then((Response) => {
                    const responseData = Response.data;
    
                    // Extract all ids and join them into a comma-separated string
                    const allIds = responseData.map((item) => item.id).join(',');
    
                    setVedhicledata(responseData); // Set the vehicle data
                    setFormData({
                        ...formData,
                        acccode: filteredData[selectedIndex]?.acccode, // Use optional chaining for safety
                        tokenno: allIds, // Set tokenno to all IDs
                    });
                })
                .catch(() => {
                    console.log("Data Not Found");
                });
        } else {
            console.log("userData is null or undefined");
        }
    }, [vehicleno, weight]);
    



    const [isLoading, setIsLoading] = useState(false);
    const [dataParty, setDataParty] = useState([])
    const [dataItem, setDataItem] = useState([])



    const handleChange = (e) => {
        const { name, value } = e.target;

        // Get the selected text of the dropdown (rawname or accname)
        const selectedText = e.target.options ? e.target.options[e.target.selectedIndex].text : "";

        // Update the form data based on the name
        setFormData((prev) => ({
            ...prev,
            [name]: value, // Update the value (e.g., rawcode or acccode)
            ...(name === "rawcode" && { rawname: selectedText }), // If rawcode, update rawname
            ...(name === "acccode" && { accname: selectedText }), // If acccode, update accname
        }));
    };

    const handleSave = async (id) => {
        const newTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        let previousWeight = weight; // Start with total weight for calculations
        // Map the `vedhicledata` to apply formula logic and add necessary fields
        const updatedData = vedhicledata.map((item, index) => {
            // Apply your formula logic to calculate weightDisplay
            const weightDisplay = index === 0
                ? previousWeight - item.weight
                : previousWeight - item.weight;
            previousWeight = item.weight; // Update previous weight for the next iteration
            // Return the updated object
            return {
                ...item, // Spread existing data
                qty: weightDisplay, // Calculated weight difference
                rate: 100, // Fixed rate
                amount: Number(weightDisplay * 100).toFixed(2), // Formula for amount
                weight:weight,
                entrytime: newTime,
                acccode: acccode,
                acccodeto: acccodeto,
                tokenno: tokenno,
                freight: freight,
                loading: loading,
                id: "",
            };
        });

        const totalAmount = updatedData.reduce((sum, item) => sum + parseFloat(item.amount), 0); // Sum of all amounts
        const billamount = Number(totalAmount) + Number(freight) + Number(loading); // Total amount + loading + freight
    
        // Add billamount to each item (if needed for individual items)
        const dataToSave = updatedData.map(item => ({
            ...item,
            billamount, // Add bill amount to each record if needed
        }));
        await axios.post(`${hostlink}/tokensale`, dataToSave)
            .then((Response) => {
                toast("Save Sucessfull");
                setTimeout(() => {
                    window.location.assign("/TokenSale")
                }, 1000);
            })
            .catch(() => {
                toast.error("Something else...")
            })
    };
    const scrollRef = useRef(null);
    const handleCancel = () => {
        setFormData({
            weight: "1000.00",
            vehicleNo: "",
            itemName: "",
        });
    };

    return (
        <div className="container mt-2">
            <div className="row">
                {/* Form Section */}
                <div className="col-lg-6 mb-4">
                    <h1 className="text-center mb-4 text-primary">Sale Entry</h1>
                    <form className="p-4 shadow rounded bg-light">
                        <div className="mb-3">
                            <label htmlFor="vehicleNo" className="form-label"> Select Vehicle No </label>
                            <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} autoComplete="off" />
                            {listVisible && filteredData.length > 0 && (
                                <ul style={{}}>
                                    {filteredData.map((item, index) => (
                                        <li
                                            key={item.vehicleno}
                                            onClick={() => handleSelectItem(item, index)}
                                            onMouseDown={() => handleItemClick(index)}

                                            className={index === selectedIndex ? 'selected' : ''}
                                        >
                                            {item.vehicleno} <span style={{ color: "red" }}>(It : {item.rawname})</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="vehicleNo" className="form-label"> Token No </label>
                            <input type="text" id="tokenno" name="tokenno" className="form-control" placeholder="Token Number" disabled value={tokenno} onChange={handleChange} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="itemName" className="form-label"> Party Name </label>
                            <select id="inputState" onChange={(e) => { handleChange(e) }} name='acccode' value={acccode} class="form-select">
                                <option selected key={0} value={0}>Choose...</option>
                                {dataParty.map((x) => { return (<option key={x.id} value={x.id}> {x.accname} </option>) })}; </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="itemName" className="form-label"> Delivered to </label>
                            <select id="acccodeto" onChange={(e) => { handleChange(e) }} name='acccodeto' value={acccodeto} class="form-select">
                                <option selected key={0} value={0}>NA</option>
                                {dataParty.map((x) => { return (<option key={x.id} value={x.id}> {x.accname} </option>) })}; </select>
                        </div>


                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary w-25" onClick={() => { handleSave(0) }} > Sale </button> &nbsp;&nbsp;&nbsp;
                            <button type="button" className="btn btn-danger w-25" onClick={handleCancel} > Cancel </button>
                        </div>
                    </form>
                </div>

                {/* CCTV and Weight Display Section */}
                <div className="col-lg-6 mb-4">
                    <div className="row">
                        <div className="col-md-7 col-12">
                            <h3 className="text-center" style={{ color: "blue" }}>Current Weight</h3>
                            <input type="number" id="weight" name="weight" className="form-control text-center" placeholder="Current Weight" value={weight} onChange={handleChange} style={{
                                fontSize: "3rem",
                                fontFamily: "'Digital-7 Mono', monospace",
                                background: "black",
                                color: "lime",
                                padding: "10px",
                                borderRadius: "10px",
                                display: "inline-block",
                                width: "100%"
                            }} />
                        </div>
                        <div className="col-md-5 col-12">
                            <h3 className="text-center" style={{ color: "blue" }}>CCTV Live Feed</h3>
                            <iframe
                                src="https://www.youtube.com/embed/live_stream?channel=sujeetraj995@gmail.com"
                                title="CCTV Live Feed"
                                className="rounded"
                                style={{ border: "none", width: "100%", height: "92px" }}
                                allowFullScreen
                            ></iframe>
                        </div>
                        {/* Grid */}
                        <div className="row mb-4">
                            <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-2 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right,rgb(185, 214, 220),rgb(184, 218, 225),rgb(202, 223, 227))', color: 'black', padding: '20px' }}>
                                <div className='row text-left align-self-center p-1 m-0 '>
                                    <div className='col-md-4'>Item Name</div>
                                    <div className='col-md-2 text-end'>Qty</div>
                                    <div className='col-md-2 text-end'>Rate</div>
                                    <div className='col-md-2 text-end'>Amount</div>
                                    <div className='col-md-2 text-end'>Action</div>
                                </div>
                            </div>
                            <div
                                className="hide-on-mobile text-left align-self-center row p-1 m-2 shadow rounded border border-1"
                                style={{ maxHeight: "365px", overflow: "auto" }}
                                ref={scrollRef}
                            >
                                {vedhicledata.map((resp, index) => {
                                    // First row: subtract the first item's weight from the totalWeight
                                    const weightDisplay = index === 0
                                        ? weight - resp.weight
                                        : vedhicledata[index - 1].weight - resp.weight; // Difference for subsequent rows
                                        const amount = weightDisplay * 100; // Calculate the amount
                                        totalAmount += amount; // Accumulate the amount into total

                                    return (
                                        <div key={index} className="row text-left p-1 m-0 bg-light border border-1">
                                            <div className="col-md-4 align-self-center">{resp.rawname}</div>
                                            <div className="col-md-2 align-self-center text-end">{weightDisplay}</div>
                                            <div className="col-md-2 align-self-center text-end">100</div>
                                            <div className="col-md-2 align-self-center text-end">{Number(weightDisplay * 100).toFixed(2)}</div>
                                            <div className="col-md-2 align-self-center text-end">
                                                <button type="submit" className="btn btn-sm btn-outline-danger">
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <p></p>
                            <div className="row">
                                <div className="col-8 text-end">Total Amount</div>
                                <div className="col-4 text-end">{totalAmount}</div>
                                <div className="col-8 text-end align-self-center"><label htmlFor="vehicleNo" className="form-label"> Freight</label></div>
                                <div className="col-4 text-end"><input type="text" id="freight" name="freight" className="form-control text-end" placeholder="Freight Amount" value={freight} onChange={handleChange} autoComplete="off" /></div>
                                <div className="col-8 text-end align-self-center"><label htmlFor="vehicleNo" className="form-label"> Loading</label></div>
                                <div className="col-4 text-end"><input type="text" id="loading" name="loading" className="form-control text-end" placeholder="Loading Amount" value={loading} onChange={handleChange} autoComplete="off" /></div>
                                <p></p>
                                <div className="col-8 text-end"><h3 style={{color:"blue"}}>Bill Amount</h3></div>
                                <div className="col-4 text-end"> <h3 style={{color:"blue"}}>{Number(Number(totalAmount) + Number(freight) + Number(loading)).toFixed(2)}</h3> </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <footer className="fixed-bottom">
                <marquee
                    className="bg-dark text-light p-1"
                    behavior="alternate"
                    direction="left"
                >
                    We offer customized software solutions that meet the
                    requirements of your business. We have multiple software
                    products with us with all required modules. (For more
                    details, contact us at: +91-9205802778, Email:
                    sujeetraj995@gmail.com)
                </marquee>
            </footer>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    );
};


export default TokenSale
