import React, { useEffect, useState } from "react";
import './TokenView.css';
import Loading from './Loading'
import decryptedKey from '../Hostlink/decryptedKey';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import axios from "axios";
import hostlink from "../Hostlink/hostlink";
const TokenNew = () => {
    
    const [userData, setUserdata] = useState(null);
    useEffect(() => {
        const encryptedData = localStorage.getItem('USERLOG');
        if (encryptedData) {
            const bytes = CryptoJS.AES.decrypt(encryptedData, decryptedKey); const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setUserdata(decryptedData);
        }
    }, []);

    useEffect(() => {
        if (userData && userData[0].id) {
            axios.get(`${hostlink}/${userData[0].id}/item`).then((Response) => {
                setDataItem(Response.data)
            }).catch((Response) => { "data Not Found" })
            axios.get(`${hostlink}/${userData[0].id}/accmas`)
                .then((Response) => {
                    setDataParty(Response.data);
                })
        } else {
            console.log("userData is null or undefined");
        }
    }, [userData])
    const [isLoading, setIsLoading] = useState(false);
    const [dataParty, setDataParty] = useState([])
    const [dataItem, setDataItem] = useState([])

    const [formData, setFormData] = useState({
        weight: "1000.00",
        vehicleno: "",
        rawcode: 0,
        acccode: 0,
        tokentype:0,
        entrytime:"",
        gateno: "",
        rawname:"",
        accname:""

    });
    const { weight, vehicleno, rawcode, acccode, gateno,tokentype,entrytime,accname,rawname } = formData;
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Get the selected text of the dropdown (rawname or accname)
        const selectedText = e.target.options ? e.target.options[e.target.selectedIndex].text : "";
    
        // Update the form data based on the name
        setFormData((prev) => ({
            ...prev,
            [name]: value, // Update the value (e.g., rawcode or acccode)
            ...(name === "rawcode" && { rawname: selectedText }), // If rawcode, update rawname
            ...(name === "acccode" && { accname: selectedText }), // If acccode, update accname
        }));
    };
    
    const handleSave = async (id) => {
        
        const newTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true});
        var ld = { ...formData,shopid:userData[0].id,entrytime: newTime,tokentype:id}
        console.log(ld);
        await axios.post(`${hostlink}/tokenmas`, ld)
            .then((Response) => {
                toast("Save Sucessfull");
                setTimeout(() => {
                    window.location.assign("/TokenNew")
        
                }, 1000);
            })
            .catch(() => {
                toast.error("Something else...")
            })
    };

    const handleCancel = () => {
        setFormData({
            weight: "1000.00",
            vehicleNo: "",
            itemName: "",
        });
    };

    return (
        <div className="container mt-2">
            <div className="row">
                {/* Form Section */}
                <div className="col-lg-6 mb-4">
                    <br />
                    <h1 className="text-center mb-4 text-primary">Token Entry</h1>
                    <form className="p-4 shadow rounded bg-light">
                        <div className="mb-3">
                            <label htmlFor="vehicleNo" className="form-label"> Vehicle No </label>
                            <input type="text" id="vehicleno" name="vehicleno" className="form-control" placeholder="Enter Vehicle Number" value={vehicleno} onChange={handleChange} autoComplete="off" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="itemName" className="form-label"> Party Name </label>
                            <select id="inputState" onChange={(e) => { handleChange(e) }} name='acccode' value={acccode} class="form-select">
                                <option selected key={0} value={0}>Choose...</option>
                                {dataParty.map((x) => { return (<option key={x.id} value={x.id}> {x.accname} </option>) })}; </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="itemName" className="form-label"> Meterial Name </label>
                            <select id="inputState" onChange={(e) => { handleChange(e) }} name='rawcode' value={rawcode} class="form-select">
                                <option selected key={0} value={0}>Choose...</option>
                                {dataItem.map((x) => { return (<option key={x.id} value={x.id}> {x.itname} </option>) })}; </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="itemName" className="form-label"> Gate No </label>
                            <input type="text" id="gateno" name="gateno" className="form-control" placeholder="Enter Gate No" value={gateno} onChange={handleChange} autoComplete="off"  />
                        </div>
                        <div className="d-flex justify-content-between">
                            <button type="button" className="btn btn-primary w-25" onClick={()=>{handleSave(0)}} > Sale </button>
                            <button type="button" className="btn btn-outline-success w-25" onClick={()=>{handleSave(1)}}> Purchase </button>
                            <button type="button" className="btn btn-danger w-25" onClick={handleCancel} > Cancel </button>
                        </div>
                    </form>
                </div>

                {/* CCTV and Weight Display Section */}
                <div className="col-lg-6 mb-4">

                    <div className="shadow rounded bg-dark text-light p-4">
                        {/* Weight Display */}
                        <div className="mb-4 text-center">
                            <h2>Current Weight</h2>
                            <input type="number" id="weight" name="weight" className="form-control text-center" placeholder="Current Weight" value={weight} onChange={handleChange} style={{
                                    fontSize: "3rem",
                                    fontFamily: "'Digital-7 Mono', monospace",
                                    background: "black",
                                    color: "lime",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    display: "inline-block",
                                    width: "100%"
                                }} />
                        </div>

                        {/* CCTV Feed */}
                        <div className="cctv-container shadow rounded">
                            <h1 className="text-center mb-4" style={{ color: "white" }}>CCTV Live Feed</h1>
                            <iframe
                                src="https://www.youtube.com/embed/live_stream?channel=sujeetraj995@gmail.com"
                                title="CCTV Live Feed"
                                className="w-100 rounded"
                                style={{ height: "300px", border: "none" }}
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="fixed-bottom">
                <marquee
                    className="bg-dark text-light p-1"
                    behavior="alternate"
                    direction="left"
                >
                    We offer customized software solutions that meet the
                    requirements of your business. We have multiple software
                    products with us with all required modules. (For more
                    details, contact us at: +91-9205802778, Email:
                    sujeetraj995@gmail.com)
                </marquee>
            </footer>
            {isLoading && <Loading />}
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    );
};


export default TokenNew
